import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import Heading from '@components/Heading'
import ButtonArrow from '@components/Button/Button.Arrow'
import Image from '@components/Image'

import media from '@styles/media'

import { IArticleNode } from '@typings'

/**
 *  [LONG], [SHORT]
 *  [SHORT], [LONG]
 *  -------
 *  QUOTE PLAEMENT
 *  -------
 *  [SHORT], [LONG]
 */

/**
 * Okay, I know, the Grid is manually setup and we’re manually selecting an
 * index from the array of articles... but it's only 8 and it works perfectly fine.
 *
 * TODO: Figure out CSS grid for reverse grid row items and inserting a quote inside.
 */
const ArticlesGrid = ({ articles }: { articles: IArticleNode[] }) => {
  return (
    <>
      <Grid>
        <GridItem article={articles[0]} />
        <GridItem article={articles[1]} narrow />
      </Grid>

      {articles[2] && (
        <Grid reverse>
          <GridItem article={articles[2]} narrow />
          <GridItem article={articles[3]} />
        </Grid>
      )}

      {articles[4] && (
        <>
          <HorizontalRule>
            <h2>From our clients</h2>
          </HorizontalRule>
          <Testimonial />

          <HorizontalRule>
            <h2>Read More</h2>
          </HorizontalRule>
          <Grid>
            <GridItem article={articles[4]} />
            <GridItem article={articles[5]} narrow />
          </Grid>
        </>
      )}

      {articles[6] && (
        <Grid>
          <GridItem article={articles[6]} narrow />
          <GridItem article={articles[7]} />
        </Grid>
      )}
    </>
  )
}

export default ArticlesGrid

const GridItem = ({ article, narrow }) => {
  if (!article) return null

  const hasOverflow = narrow && article.title.length > 35

  return (
    <ArticleLink to={`/articles/${article.slug}`} data-a11y="false">
      <Item>
        <ImageContainer background={article.backgroundColor} narrow={narrow}>
          <Image src={article.backgroundImage.fluid} />
        </ImageContainer>
        <Title dark hasOverflow={hasOverflow}>
          {article.title}
        </Title>
        <Excerpt narrow={narrow} hasOverflow={hasOverflow}>
          {article.excerpt}
        </Excerpt>
        <TimeToRead>{article.readingTime.text}</TimeToRead>
      </Item>
    </ArticleLink>
  )
}

const Testimonial = () => {
  return (
    <TestimonialGrid>
      <HopperLogo aria-hidden="true" />
      <div>
        <Blockquote to="/articles/how-we-work">
          “Muse Zade is all about its quality service and work ethics. To know how we will be serving your needs, you can read this blog.”
        </Blockquote>
        <ButtonArrow
          text="Read more"
          color="#000"
          as={Link}
          to="/articles/how-we-work"
        />
      </div>
    </TestimonialGrid>
  )
}

const wide = '1fr'
const narrow = '457px'
const limitToTwoLines = css`
  text-overflow: ellipsis;
  overflow-wrap: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;

  ${media.phablet`
    -webkit-line-clamp: 3;
  `}
`

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${p =>
    p.reverse ? `${narrow} ${wide}` : `${wide} ${narrow}`};
  grid-template-rows: 2;
  column-gap: 30px;
  margin-bottom: 80px;

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}

  ${media.tablet`
    grid-template-columns: 1fr;
    margin-bottom: 0;
  `}
`

const ImageContainer = styled.div`
  position: relative;
  height: 280px;
  box-shadow: 0 30px 60px -10px rgba(0, 0, 0, ${p => (p.narrow ? 0.22 : 0.3)}),
    0 18px 36px -18px rgba(0, 0, 0, ${p => (p.narrow ? 0.25 : 0.33)});
  border-radius: 5px;
  margin-bottom: 30px;
  background-color: ${p => p.background};
  transition: transform 0.3s var(--ease-out-quad),
    box-shadow 0.3s var(--ease-out-quad);

  & > div {
    height: 100%;
    border-radius: 5px;
  }

  ${media.tablet`
    height: 240px;
    margin-bottom: 0;
    box-shadow: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  `}

  ${media.phablet`
    height: 200px;
  `}
`

const Item = styled.div`
  position: relative;

  ${media.tablet`
    margin-bottom: 40px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  `}
`

const Title = styled(Heading.h2)`
  font-size: 22px;
  margin-bottom: ${p => (p.hasOverflow ? '45px' : '10px')};
  transition: color 0.3s ease-in-out;
  ${limitToTwoLines};

  ${media.tablet`
    padding: 30px 20px 0;
    margin-bottom: 10px;
    -webkit-line-clamp: 3;
  `}
`

const Excerpt = styled.p`
  ${limitToTwoLines};
  font-size: 18px;
  margin-bottom: 10px;
  color: ${p => p.theme.colors.grey};
  display: ${p => (p.hasOverflow ? 'none' : 'box')};
  max-width: ${p => (p.narrow ? '415px' : '515px')};

  ${media.desktop`
    display: -webkit-box;
  `}

  ${media.tablet`
    max-width: 100%;
    padding:  0 20px;
    margin-bottom: 20px;
    -webkit-line-clamp: 3;
  `}
`

const TimeToRead = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${p => p.theme.colors.grey};
  opacity: 0.5;

  ${media.tablet`
    max-width: 100%;
    padding:  0 20px 30px;
  `}
`

const ArticleLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  z-index: 1;
  transition: transform 0.33s var(--ease-out-quart);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:hover ${ImageContainer}, &:focus ${ImageContainer} {
    transform: translateY(-1px);
    box-shadow: 0 50px 80px -20px rgba(0, 0, 0, 0.27),
      0 30px 50px -30px rgba(0, 0, 0, 0.3);
  }

  &:hover h2,
  &:focus h2 {
    color: ${p => p.theme.colors.purple};
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -1.5%;
    top: -2%;
    width: 103%;
    height: 104%;
    border: 3px solid ${p => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${media.tablet`
    &:hover ${ImageContainer} {
      box-shadow: none;
    }

    &:hover h2 {
      color: #000;
    }

    &:active {
      transform: scale(0.97) translateY(3px);
    }
  `}
`

const TestimonialGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${(195 / 1140) * 100}% ${(945 / 1140) * 100}%;
  grid-template-rows: 1;
  column-gap: 34px;
  margin-bottom: 80px;

  ${media.tablet`
    grid-template-columns: 1fr;
    padding: 0 20px;
    margin-bottom: 60px;

    & > svg {
      height: 25px;
      width: 94.29px;
      margin-bottom: 15px;
    }
  `}
`

const Blockquote = styled(Link)`
  display: block;
  font-family: ${p => p.theme.fontfamily.serif};
  font-size: 36px;
  font-weight: 500;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  line-height: 1.2;
  max-width: 852px;
  margin-bottom: 30px;
  transition: color 0.3s ease-in-out;

  &:hover,
  &:focus {
    color: #000;
  }

  &:focus {
    text-decoration: underline;
  }

  ${media.tablet`
    font-size: 24px;
    margin-bottom: 20px;
  `}
`

const HorizontalRule = styled.div`
  position: relative;
  color: rgba(0, 0, 0, 0.25);
  margin-bottom: 80px;

  ${media.tablet`
    margin-bottom: 60px;
  `}

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 12px;
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.25);
  }

  & > h2 {
    position: relative;
    display: inline-block;
    background: #fafafa;
    font-weight: 400;
    z-index: 0;
    width: ${(230 / 1140) * 100}%;

    ${media.tablet`
      width: auto;
      padding-right: 20px;
    `}
  }
`

const HopperLogo = () => (
  <svg
    width="132"
    height="35"
    viewBox="0 0 132 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Hopper Logo</title>
    <g>
  <text x="50%" y="50%" 
  dominant-baseline="middle" text-anchor="middle"  font-weight="bold" font-family="Verdana" font-size="35" fill="red">officialBlogs</text>
</g>
  </svg>
)
